import React from 'react';
import { EntityConstants, EntityProp } from '../../../api';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, Images, Common } from '../../../core';

import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { Source } from '../../../api/source.service';

export const DialogConstants = {
    Screen: {
        FULL: 'screen-full',
        CENTER: 'screen-center',
        INLINE: 'screen-inline',
    }
}

export interface DialogProps {
    title?: any,
    footer?: any,
    onClose?: Function,
    onResize?: Function,
    minimized?: boolean,
    callback?: Function,
    show?: boolean,
    screenMode?: string,
    timeout?: number,
    onTimedOut?: Function,
    styles?: any,
}

export const DialogCompProps: Array<EntityProp> = [
    { groupID: 'General', id: 'title', label: 'Title' },
    { groupID: 'General', id: 'resizable', label: 'Resizable?', dataType: EntityConstants.PropType.BOOLEAN },
    { groupID: 'General', id: 'minimized', label: 'Is Minimized?', dataType: EntityConstants.PropType.BOOLEAN },
    { groupID: 'General', id: 'anchorID', label: 'Anchor ID', validators: [EntityConstants.Validator.SYSTEM_ID] },
    { groupID: 'Style', id: 'titleStyles', label: 'Titlebar Styles', dataType: EntityConstants.PropType.JSON, },
    { groupID: 'Style', id: 'containerStyles', label: 'Overall Styles', dataType: EntityConstants.PropType.JSON, },
    { groupID: 'Style', id: 'containerClasses', label: 'Overall Style Classes', },
    { groupID: 'Events', id: 'onClose', label: 'On Close Handler', dataType: EntityConstants.PropType.FUNCTION },
    { groupID: 'Events', id: 'onResize', label: 'On Resize Handler', dataType: EntityConstants.PropType.FUNCTION },

]

@UnoComponent({
    id: 'Dialog',
    props: DialogCompProps,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    isContainer: true,
})
export class Dialog extends UnoCoreBaseComp {
    CLASS_DISPLAY_NONE = ' display-none ';
    CLASS_DISPLAY_BLOCK = ' display-block ';

    // closed = false;
    maximized = true;
    timeoutHandler: any;

    constructor(props: any) {
        super(props);
        let screenMode = DialogConstants.Screen.CENTER;
        if (this.props.screenMode) {
            screenMode = this.props.screenMode;
        }
        this.state = { ...this.state, screenMode: screenMode };
        if (this.state.minimized) {
            this.maximized = false;
        }

        // this.closed = !((this.state.show != undefined) ? Boolean(this.state.show).valueOf() : true);
    }

    componentDidMount() {
        super.componentDidMount();
        const timeout = this.props.timeout;
        if (timeout) {
            const outTime = timeout * 1000;

            // console.log(`Timeout after ${(new Date().getTime() + outTime)} ms.`,);
            this.timeoutHandler = setTimeout(() => {
                this.doClose();

                if (this.state.onTimedOut) {
                    this.state.onTimedOut();
                }

            }, outTime);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            // console.log(`Dialog's timeout cleared.`);
        }
    }

    buildComp() {
        if (this.isClosed() && this.isLiveMode()) {
            return <UC.Empty />;
        }

        const titlebar = this.buildTitleBar(this.getTitleStyles());

        const id = (this.state.anchorID) ? this.state.anchorID : (this.state.compConfig ? this.state.compConfig.id : Common.getUniqueKey('dialog_'));

        if (this.state.screenMode === DialogConstants.Screen.INLINE) {
            const border = titlebar ? { border: '1px solid #D8D8D8' } : undefined;
            let style: any = { ...border, ...this.getContentStyles() };
            if (!this.isLiveMode()) {
                style.position = 'relative';
                style.zIndex = '0';
            }

            return (
                <div key={Common.getUniqueKey('dialog_')} id={id}
                    style={{
                        // display: 'inline',
                        ...this.getContainerStyles(),
                    }}
                    className={`${this.getContainerStyleClasses()}`}
                >
                    {titlebar}
                    {this.buildContent(style)}
                    {this.buildFooter()}
                </div>
            )
        } else {
            const isFullScreen = this.isFullScreen();
            const isModal = this.isModal();
            const dimension = { width: isFullScreen ? '100%' : 'auto', height: isFullScreen ? '100%' : 'auto' };

            return (
                <div key={id} id={id}
                    style={
                        {
                            position: 'fixed',
                            zIndex: 100,
                            top: `${isModal ? '0px' : '50%'}`,
                            left: `${isModal ? '0px' : '50%'}`,
                            transform: `${isModal ? 'none' : 'translateX(-50%) translateY(-50%)'}`,

                            width: `${isModal ? '100%' : 'auto'}`,
                            height: `${isModal ? '100%' : 'auto'}`,
                            margin: 'auto',

                            display: `${this.isClosed() ? 'none' : 'flex'}`,
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `rgba(255, 255, 255, ${(isFullScreen) ? 1 : 0.8})`,

                        }
                    }
                >
                    <div
                        style={{ ...dimension, maxHeight: '100%', overflow: 'auto', ...this.getContainerStyles() }}
                        className={`${this.getContainerStyleClasses()}`}
                    >
                        {titlebar}
                        {this.buildContent({ ...this.getContentStyles() })}
                        {this.buildFooter()}
                    </div>
                </div >
            );
        }

    }

    getContentStyles() {
        return Common.safeParse(this.state.styles);
    }

    getTitleStyles() {
        return Common.safeParse(this.state.titleStyles);
    }

    getContainerStyles() {
        return Common.safeParse(this.state.containerStyles);
    }

    getContainerStyleClasses() {
        return Common.safeParse(this.state.containerClasses);
    }

    isFullScreen() {
        return this.state.screenMode === DialogConstants.Screen.FULL;
    }

    isModal() {
        return (this.state.modal === undefined) ? true : this.state.modal;
    }

    isDialog() {
        return true;
    }

    buildContent(styles: any = this.getContentStyles()) {
        let childrenClasses = ` dialog-content ${this.isDialog() ? 'bkg-opaque' : 'bkg-transparent'} ${this.getStyleClasses()}`;
        if (this.maximized === false && this.isLiveMode()) {
            return undefined;
            /*
            return (
                <div className={childrenClasses} style={styles}>
                </div>
            );
            */
        }

        const content = this.state.content
        if (content) {
            return (
                <>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </>
            );
        } else {

            // let propStyles: any = this.state?.styles ? Common.safeParse(this.state.styles) : {};
            return (
                <div className={childrenClasses} style={styles}>
                    {this.layChildren()}
                </div>
            );
        }
    }

    layChildren() {
        return (<>{this.props.children}</>);
    }

    buildFooter() {
        return (
            <>
                {this.state.footer ? <div style={{ bottom: '0px', width: '100%', borderTop: '1px groove black', position: 'sticky', }}>{this.state.footer}</div> : <></>}
            </>
        );
    }

    async doClose(evt?: any, newState: any = {}) {
        let canClose = true;
        let onCloseHandler = this.getOnCloseHandler();
        if (onCloseHandler) {
            if (Common.checkType.String(onCloseHandler)) {
                const inputs = { theComp: this, target: evt.target, evt: evt };
                onCloseHandler = Source.getFunction(onCloseHandler);
                canClose = await onCloseHandler(inputs);
            } else if (Common.checkType.Function(onCloseHandler)) {
                canClose = await onCloseHandler(evt);
            }
        }
        if (canClose === false) {
            return;
        }

        // this.closed = true;
        this.setState({ show: false, ...newState });
        if (evt) {
            // console.log('Closed: ', this.props.displayMode, evt,);
        }
    }

    doResize = async (evt?: any) => {
        this.maximized = !this.maximized;
        this.setState({});

        await this.execOnResize(evt);
    }

    async execOnResize(evt?: any) {
        let fnResize = Common.safeParse(this.state.onResize);
        if (Common.checkType.String(fnResize)) {
            const inputs = { theComp: this, target: evt.target, evt: evt };
            fnResize = Source.getFunction(fnResize);
            fnResize(inputs);
        } else if (Common.checkType.Function(fnResize)) {
            fnResize(evt);
        }
    }

    async doShow(evt?: any, newState: any = {}) {
        // this.closed = false;
        this.setState({ show: true, ...newState });
        if (evt) {
            console.log('Showing: ', this.props.displayMode, evt);
        }
        await this.execOnResize(evt);
    }

    needTitleBar() {
        if (this.state.title || this.hasActions()) {
            return true;
        }
        return false;
    }

    buildTitleBar(styles: any = this.getTitleStyles()) {
        if (!this.needTitleBar()) {
            // to be cotrolled via 'show' property managed from parent.
            return null;
        }

        return (
            <div
                className={this.getTitleStyleClasses()}
                style={{
                    ...styles,
                    // position: 'sticky',
                    // top: 0,
                }}
                onClick={(evt) => {
                    if (this.isResizable()) {
                        this.doResize(evt);
                    }
                }}
            >
                {this.buildTitleLabel()}
                {this.buildActionIcons()}
            </div>
        );
    }

    buildTitleLabel() {
        let title: any = this.state.title;
        if (title) {
            title = <span>{title}</span>;
        }
        return title;
    }

    buildActionIcons() {
        if (!this.hasActions()) {
            return (<></>);
        }
        return (
            <span className='float-right'>
                {this.buildOnResizeAction()}
                {this.buildOnCloseAction()}
            </span>
        );
    }

    hasActions() {
        return (this.getOnCloseHandler() || this.isResizable());
    }

    buildOnCloseAction() {
        let onCloseHandler: any = (<></>);
        if (this.getOnCloseHandler()) {
            onCloseHandler = (
                <img
                    src={Images.Icon.close}
                    alt='X'
                    className='tool-icon'
                    onClick={async (evt: any) => { await this.doClose(evt); }}
                    title={`Click to CLOSE.`}
                />
            );
        }

        return onCloseHandler;
    }

    getOnCloseHandler() {
        // console.log('On Close Handler: ', this.state.onClose);
        return Common.safeParse(this.state.onClose);
    }

    getResizeIcon() {
        if (this.maximized) {
            return Images.Icon.minimize;
        } else {
            return Images.Icon.maximize;
        }
    }

    buildOnResizeAction() {
        let onRresizeHandler: any = (<></>);

        if (this.isResizable()) {
            onRresizeHandler =
                (
                    <img
                        src={this.getResizeIcon()}
                        alt='R'
                        className='tool-icon'
                        // onClick={this.doResize}
                        title={this.getResizeActionTitle()}
                    />
                );
        }

        return onRresizeHandler;
    }

    getResizeActionTitle(): string | undefined {
        return `Click to ${this.maximized ? 'HIDE' : 'SHOW'} the content.`;
    }

    isResizable() {
        return (this.state.resizable === true) || this.state.onResize || (this.state.minimized === true);
    }

    getTitleStyleClasses() {
        let dialogTitleClasses = ' dialog-title ';
        if (this.state.screenMode === DialogConstants.Screen.INLINE) {
            dialogTitleClasses += ' dialog-title-inline ';
        }
        return dialogTitleClasses;
    }

    getStyleClasses() {
        const styleClasses = this.state.styleClasses;
        if (styleClasses) {
            return styleClasses;
        } else {
            return '';
        }
    }

    calculateDialogClass() {
        if (this.isClosed()) {
            // return this.CLASS_DISPLAY_NONE;
        }

        let classNames = this.state.screenMode;
        const propShow = 'show';
        if (propShow in this.props) {
            classNames = classNames + (this.props[propShow] ? this.CLASS_DISPLAY_BLOCK : this.CLASS_DISPLAY_NONE);
        }
        // classNames += (this.isVisible() ? this.CLASS_DISPLAY_BLOCK : this.CLASS_DISPLAY_NONE);
        classNames += ((this.isDialog()) ? ' bkg-opaque ' : ' bkg-transparent ');

        return classNames;
    }

    isClosed = () => {
        const visible = (this.state.show === false);
        // console.log('Dialog Visible - ', visible)
        return visible;
    }

    setTitle = (title: string) => {
        this.setState({ title: title });
    }

    isLiveMode = () => {
        return DesignerConstants.isLiveMode(this);
    }

}
