import React from 'react';
import { Link } from 'react-router-dom';
import { SessionManager } from '../../@uno-app/service/session.service';
import { BaseEntity, Common, FilterConstants, Router } from '../../@uno/api';
import { EntityProp } from '../../@uno/api/entity.service';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { EntityViewTypes } from '../service/entity-view.service';
import { ENTITY_COMP_PROPS } from './entity-base.comp';
import { ENTIY_EDIT_COMP_PROPS, EntityEdit } from './entity-edit.comp';

// ENTITY - VIEW
@UnoComponent({
    id: 'EntityView',
    label: 'Entity Full View',
    paletteable: true,
    props: [
        ...ENTIY_EDIT_COMP_PROPS,
        ...ENTITY_COMP_PROPS,
    ],
    // getDesign: () => { return (<div>View Entity - Design</div>); },
    // getPreview: () => { return (<div>View Entity - Preview</div>); }
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityView extends EntityEdit {
    protected isQuickView: boolean = false;

    constructor(props: any) {
        super(props);
        // console.log(`Viewing Entity ID : ${this.props.entityID} of Category: ${this.props.categoryID}`);
        if (this.props.otherProps?.viewType === EntityViewTypes.QUICK) {
            this.isQuickView = true;
        }
    }

    buildContent() {
        const entity = this.getEntity();
        if (entity && Object.keys(entity).length > 0) {
            return super.buildContent();
        } else {
            return (<UC.Empty />);
        }
    }

    getAction(): string {
        return (this.isQuickView) ? Router.CatAction.QUICK_VIEW : Router.CatAction.VIEW;
        //return Router.CatAction.VIEW;
    }

    async loadEntity(id: string, callback?: any) {
        if (this.isQuickView) {
            // Identify quick view fields
            const fields: any = {};
            const qvProps = this.getQuickViewProps();
            for (let p of qvProps) {
                fields[p.id] = 1;
            }

            const filters = [
                FilterConstants.setCategory(this.getCategoryID()),
                FilterConstants.create('_id', id),
                FilterConstants.create('app_id', this.getAppID()),
            ];
            const res = await FilterConstants.find(filters, { limit: 1, fields: fields }, this.getAppID(), this.getSession());
            if (res?.length > 0) {
                this.handleEntityLoaded(res[0], callback);
            }
        } else {
            super.loadEntity(id, callback);
        }
    }

    getEditLink() {
        // show edit button
        let editLinkElement = (null);
        if (this.canEdit()) {
            const editLink = this.buildRoute(Router.CatAction.EDIT, this.getEntityId());
            editLinkElement = (
                <Link to={editLink}><button>Edit</button></Link>
            )
        }
        return editLinkElement;
    }

    canSelect() {
        let canSelect = false;
        // console.log('Select - Other Props', this.getOtherProps(), this.getOtherProps()?.canSelect);
        const otherProps = this.getOtherProps();
        if (otherProps?.canSelect !== undefined) {
            canSelect = otherProps.canSelect;
        }
        return canSelect;
    }

    handleSelected = () => {
        this.getOtherProps()?.onSelected(this.state.entity);
    }

    handleInlineSave = (reply: any) => {
        const entity: BaseEntity = this.state.entity;
        if (!entity) {
            return;
        }
        const otherProps = this.getOtherProps();
        if (otherProps?.onInlineSave) {
            this.hideSavingMsg();
            otherProps.onInlineSave(entity);
        } else {
            entity.save(this.getAppID(), SessionManager.activeSession).then(
                () => {
                    this.setState({});
                }
            );
        }
    }

    buildEntityProp(prop: EntityProp, noLabel: boolean = false) {
        if ((this.isQuickView && prop.noQV) || prop.hidden) {
            return undefined;
        }

        let entity = this.getEntity();
        let value = this.getPropValue(prop, entity);
        const otherProps = this.buildOtherPropsForEProp(noLabel, prop);
        const xyz = {
            ...this.getOtherProps(),
            onInlineSave: this.handleInlineSave,
            viewType: EntityViewTypes.QUICK,
            hideLabel: noLabel,
        }
        // console.log(`Category:${this.getCategory().id}, Prop: ${p.id}, Value: ${value}`);
        return (
            <UC.PropComp
                key={this.getUniqueKey(prop)}
                entityProp={prop}
                categoryID={this.getCategoryID()}
                defaultValue={value}
                entity={entity}
                otherProps={otherProps}
                appID={this.getAppID()}
            />
        );

    }

    getSelectRoute() {
        if (this.canSelect()) {
            return { id: 'select', label: 'Select', action: this.handleSelected };
        }
        return undefined;
    }

    canViewFull() {
        let canViewFull = super.canViewFull();

        if (!canViewFull) {
            return canViewFull;
        } else {
            const viewType = this.getOtherProps()?.viewType;
            if (this.isQuickView || (viewType && viewType !== EntityViewTypes.FULL)) {
                canViewFull = true;
            } else {
                canViewFull = false;
            }
        }
        return canViewFull;
    }

    getActionLinks() {
        const actionLinks: Array<any> = [];
        const selectRoute = this.getSelectRoute();
        if (selectRoute) {
            actionLinks.push(selectRoute);
        }

        const editRoute = this.getEditRoute();
        if (editRoute) {
            actionLinks.push(editRoute);
        }

        const fullViewRoute = this.getFullViewRoute();
        if (fullViewRoute) {
            actionLinks.push(fullViewRoute);
        }

        if (this.getOtherProps().actions) {
            actionLinks.push(this.getOtherProps().actions);
        }

        // Add View Actions from Category
        this.addCatActions(actionLinks);


        const mainRoute = this.getMainRoute();
        if (mainRoute && !this.isQuickView) {
            actionLinks.push(mainRoute);
        }

        // console.log(`Action Links - `, actionLinks);
        return actionLinks;
    }

    getPrinterComp(): any {
        return null;
        // return EntityView;
    }

    getPrintViewID(): any {
        return this.printViewID;
    }

    canPrint() {
        return this.isQuickView ? false : super.canPrint();
    }

    canSetPermission() {
        return this.isQuickView ? false : super.canSetPermission();
    }

    canEdit() {
        return this.isQuickView ? false : super.canEdit();
    }
}