import ASYNC from 'async';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import { Common, UC, UnoComponentManager, UnoModuleManager } from '../core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/msal';
import { googleConfig } from './auth/google';
import { FacebookProvider } from 'react-facebook';

const EMPTY = (<> </>);
const DEFAULT_VIEW = (<h1>Welcome!</h1>);
const rootElementID = 'root';
/**
 * Prepares and maintains the Uno Web UI Platforms
 */
export class Platform {

    public static boot(module: any) {
        const container = document.getElementById(rootElementID);
        const root = createRoot(container!);
        const msalInstance = new PublicClientApplication(msalConfig);

        root.render(
            <FacebookProvider appId="2231242150402662">
                <MsalProvider instance={msalInstance}>
                    <GoogleOAuthProvider clientId={googleConfig.clientID}>
                        <WebUI module={module} />
                    </GoogleOAuthProvider>
                </MsalProvider>
            </FacebookProvider>
        );
        // Common.Styles.printCSSRules();
        // Common.startObservation(rootElementID);
    }

}

// the root UI Component
class WebUI extends React.Component<{ module: any }, any> {
    protected bootComplete = false;
    private messages: Array<any> = [];
    private initPropsOnBoot: any;

    render() {
        let PV = <UC.Empty />;
        if (this.bootComplete) {
            // console.log(`Boot Complete. Loaded components - `, Object.keys(UC));
            const bootModule = UnoModuleManager.getBootModule();
            const bootComps = bootModule?.bootstrap;
            // console.log(`Boot Module and bootstrap comp - `, bootModule, bootComps);
            if (bootComps) {
                const bsCompViews = bootComps.map(
                    comp => {
                        // console.log(`BootStrap Component -`, compID);
                        const initProps = { ...this.initPropsOnBoot };
                        let View = UC.Empty;
                        if (comp) {
                            if (Common.checkType.Function(comp)) {
                                View = comp;
                            } else if (Common.checkType.String(comp)) {
                                const compID: any = comp;
                                View = UC[compID];
                            }
                        }
                        return <View key={Common.getUniqueKey()} {...initProps} />;
                    });
                PV = (
                    <Router>
                        {bsCompViews}
                    </Router>
                );
            } else {
                PV = DEFAULT_VIEW;
            }

        } else {
            PV = (
                <div key={Common.getUniqueKey('webui_msgs_')}>
                    {this.messages}
                </div>
            );
        }

        return (<>{PV}</>);
    }

    private setBootStatus(msg?: string) {
        if (!msg) {
            this.bootComplete = true;
        } else {
            const time = new Date().toString()
            console.log(`${time} : ${msg}`);
            this.messages.push((<div key={Common.getUniqueKey()}>{time} : <b> {msg}</b></div>));
        }
        // console.log(`Refresh Platform`);
        this.setState({});
    }

    componentDidMount() {
        this.doBoot();
    }

    doBoot() {
        this.setBootStatus('Booting Up...');
        new Promise(
            (resolve, reject) => {
                ASYNC.parallel(
                    {
                        modules: (clbk: Function) => {
                            this.bootup(this.props.module)
                                .then(
                                    (module: any) => {
                                        this.setBootStatus(`Boot-strapped - ${module ? module.constructor?.name : ''}`);
                                        clbk(null, module);
                                    }
                                ).catch(err => { clbk(err) });
                        }
                    },
                    (err: any, res: any) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(true);
                        }
                    }
                );
            }
        ).then(res => {
            this.setBootStatus();
        }).catch(e => {
            this.setBootStatus(`ERROR Booting: ${e.message}`);
        });
    }

    bootup(TheBootModule: any,) {
        return new Promise(
            (resolve, reject) => {
                const appM: any = new TheBootModule();
                if (appM instanceof BootableModule) {
                    appM.unoOnBoot((msg: any) => { this.setBootStatus(msg) })
                        .then(
                            res => {
                                this.initPropsOnBoot = appM.getInitProps();
                                resolve(appM);
                            }
                        ).catch(err => {
                            // console.log(`Error initing module ${TheBootModule}`, err)
                            reject(err);
                        });
                } else {
                    resolve(appM);
                }
            }
        );
    }
}

const getTemplate = (id: string) => {
    const template = UnoComponentManager.getDef(id)?.template;
    if (template) {
        return template;
    } else {
        return () => {
            return (<></>);
        }
    }
}

/**
 * To be extended by boot module
 */
export abstract class BootableModule {
    abstract unoOnBoot(updateStatus: Function): Promise<any>;

    abstract getInitProps(): any;
}