import React from 'react';
import { Common, Images, UnoComponent, } from '../../../core';
import WritePad from './write-pad.comp';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

@UnoComponent({
    id: 'Writer',
})
export class WriterComp extends UnoCoreBaseComp {
    buildComp() {
        const showWritePad = async () => {
            Common.showDialog(
                {
                    comp: (
                        <WritePad
                            onSubmit={
                                async (transcript: string) => {
                                    const onSubmitFn = this.state.onSubmit;
                                    if (Common.checkType.Function(onSubmitFn)) {
                                        onSubmitFn(transcript);
                                    }

                                    Common.hideDialog();
                                }
                            }
                        />
                    ),
                    title: 'Write',
                    onClose: () => { }
                }
            );
        }

        return (
            <div style={{ display: 'inline', margin: '5px' }}>
                <img
                    src={Images.Icon.writepad}
                    title={'Click to Write'}
                    className='tool-icon'
                    onClick={showWritePad}
                />
            </div>
        );
    }
}