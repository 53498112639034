import React from 'react';
import { EM, EntityCategory, RemoteService } from '../../@uno/api';
import { Common, UC, UnoComponent } from '../../@uno/core';
import { DesignerConstants } from '../../@uno/defs/uno-component';
import { AppInfo, AppInfoService } from '../service/app.info.service';
import { AppScreenService } from '../service/app.screen.service';
import defaultAppLayout from './uno-app.layout.json';
import { EntityCategoryService } from '../service/entity.category.service';
import { Profiler } from '../../@uno/api/common.service';
import { AppRouterService } from '../service/app.router.service';
import { Redirect } from 'react-router-dom';

@UnoComponent({ id: 'AppLayout' })
export class AppLayout extends React.Component<{
    error?: any,

    appInfo?: AppInfo,
    appID?: string,
    category?: EntityCategory,
    entityID?: string,
    action?: string,

    header?: string,
    profile?: string,
    primaryMenu?: string,
    component?: string,
    footer?: string,
}, any> {
    private compID = Common.getUniqueKey(`${this.constructor.name}_`);
    protected profiler = Profiler.init(`${this.compID}`, false);

    private redirectTo: any = undefined;
    private _EH_AppSwitch: any;

    private theAppLayout: any = undefined;
    private appInfo: AppInfo | undefined;
    private styleSheet: string = '';


    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
        // this.initApp();
        this.profiler.log(this.compID, 'Constructed');
    }


    componentDidMount() {
        // super.componentDidMount();
        this._EH_AppSwitch = EM.register(Common.Event.APP_SWITCHED, this.handleAppSwitched);
        EM.register(Common.Event.SCREEN_COMPS_LOADED, (appID?: string) => {
            this.setState({});// force re-render;
            this.profiler.log(this.compID, 'Screen Comps Loaded.', appID, this.getAppID());
        });

        this.initApp();
    }

    componentWillUnmount() {
        EM.unregister(this._EH_AppSwitch);
    }


    render() {
        this.profiler.log(this.compID, 'Rendering');

        let appLayout: any = (
            <UC.Section styleClasses='center'>
                <h1>Preparing</h1>
                <UC.Loading />
            </UC.Section>
        );

        if (this.theAppLayout) {
            appLayout = this.buildAppLayout();
        } else {
            // appLayout = this.buildPage();
        }

        return (
            <>
                {this.buildRedirect()}
                <div
                    id={this.compID}
                    key={this.compID}
                    className='app-layout'
                    onLoad={
                        () => {
                            const contentEle: any = document.getElementById(this.compID);
                            // this.profiler.log('LayoutLoaded: ', (contentEle.clientHeight < contentEle.scrollHeight));
                        }
                    }

                    onScroll={
                        () => {
                            const contentEle: any = document.getElementById(this.compID);
                            if (contentEle && contentEle.clientHeight < contentEle.scrollHeight) { // scrollable container
                                if (contentEle.scrollTop > 0) {
                                    // this.profiler.log('Layout is scrolled down to: ', contentEle.scrollTop);
                                }
                                EM.emit(Common.Event.CONTENT_SCROLLED, { element: contentEle, scroll: { top: contentEle.scrollTop, left: contentEle.scrollLeft } });
                            }
                        }
                    }>
                    <style type='text/css'>{`${this.styleSheet}`}</style>

                    {appLayout}
                </div>
            </>
        );
    }

    buildRedirect() {
        let View = undefined;
        if (this.redirectTo) {
            this.profiler.log(this.compID, 'Building Redirect to:', this.redirectTo);
            const redirectView = <Redirect to={this.redirectTo} push={true} />
            this.redirectTo = undefined;
            View = redirectView;
        }
        this.initApp();

        return View;
    }

    buildAppLayout() {
        const layout = this.theAppLayout;
        if (!layout) {
            return undefined;
        }
        layout.props = { appID: this.getAppID(), otherProps: {} };

        // this.profiler.log(`Building comp ${this.constructor.name}. Layout =  `, layout);
        return (
            <UC.LayoutRenderer
                config={layout}
                mode={DesignerConstants.Mode.LIVE}
                key={Common.getUniqueKey()}
            />
        );
    }

    // Build the page.
    protected buildPage() {

        return (
            <div key={Common.getUniqueKey()} style={{ display: 'flex', flexDirection: 'column' }}>
                {this.buildTop()}
                {this.buildMiddle()}
                {this.buildBottom()}
            </div>
        );
    }

    async initApp(appID = this.getAppID()) {
        if (!appID) {
            const route = window.location.pathname;
            const hostname = window.location.hostname;
            this.profiler.log('Process route to find appID: ', hostname, route);

            const routeResult = await AppRouterService.process(route, hostname);
            appID = routeResult?.appID;
            this.redirectTo = routeResult?.redirectTo;
            if (this.redirectTo) {
                this.profiler.log('Redirect recommended: ', routeResult);
                this.setState({});
                return;
            } else if (appID) {
                this.profiler.log('Load App Info: ', hostname, route, appID);
                await AppInfoService.loadApp(appID);
            }
        }

        if (appID && !this.theAppLayout) {
            this.profiler.log('Refresh App Layout: ', appID);
            this.refreshAppLayout(appID);
        }
    }

    handleAppSwitched = async (app: AppInfo) => {
        await this.setAppInfo(app);
        await this.refreshAppLayout(this.getAppID(),);
    }

    private async refreshAppLayout(appID: string | undefined) {
        this.profiler.log('Refresh App Layout - ', appID);

        // await this.reloadAppActiveSession();
        // EntityCategoryService.addAppCategory(EventHandlerCategory, this.getAppID());
        await EntityCategoryService.reloadCategories(appID);
        this.styleSheet = await AppInfoService.getStyles(appID);
        // this.profiler.log(`App: ${appID} - styles: `, this.styleSheet);

        this.profiler.log('Try to load App Layout for : ', appID);
        const newLayout = await AppScreenService.getAppLayout(appID) || defaultAppLayout;
        if (this.theAppLayout?.id !== newLayout?.id) {
            this.theAppLayout = newLayout;
            // this.profiler.log(`refresh app layout.`);
            this.profiler.log(this.compID, 'App Switched with new Layout', appID);
            this.setState({});
        }
    }

    async setAppInfo(app: AppInfo) {
        this.appInfo = app;
        // set app title and icon
        const title = app.title || app.id || 'Uno App';
        Common.AppSettings.setTitle(title);
        if (app.logo) {
            const isImageData = app.logo && app.logo.startsWith('data:image');
            const src = isImageData ? app.logo : RemoteService.getFullURL(app.logo);
            if (!isImageData && src) {
                Common.AppSettings.setIcon(src);
            }
        }
    }

    protected getAppInfo() {
        if (!this.appInfo) {
            this.appInfo = this.props.appInfo;
        }
        return this.appInfo;
    }

    protected getContentComponent() {
        return this.props.component;
    }

    protected getEntityID() {
        return this.props.entityID;
    }

    protected getEntityAction() {
        return this.props.action;
    }

    protected getCategory() {
        return this.props.category;
    }

    protected getAppID() {
        const appID = this.props.appID || this.getAppInfo()?.id;
        return appID;
    }

    protected getHeader() {
        return this.props.header ? UC[this.props.header] : UC.Header;
    }

    protected getProfile() {
        return this.props.profile ? UC[this.props.profile] : UC.Profile;
    }

    protected getFooter() {
        return this.props.footer ? UC[this.props.footer] : UC.Footer;
    }

    protected getPrimaryMenu() {
        return this.props.primaryMenu ? UC[this.props.primaryMenu] : UC.PrimaryMenu;
    }

    buildTop() {
        const Header = this.getHeader();
        const Profile = this.getProfile();
        return (
            <div style={{ display: 'flex' }} >
                <div style={{ flex: 1 }}> <Header appID={this.getAppID()} /></div>
                <div><Profile appID={this.getAppID()} /></div>
            </div>
        );
    }

    buildMiddle() {
        const horizontal = false;
        return (
            <div style={{ display: 'flex', flexDirection: horizontal ? 'column' : 'row' }}>
                <div style={{ margin: '0 10px' }}> {this.buildPrimaryMenu(horizontal)} </div>
                <div style={{ flex: 1 }}>{this.buildContent()}</div>
            </div>
        );
    }

    buildPrimaryMenu(horizontal: boolean = false) {
        const PrimaryMenu = this.getPrimaryMenu();

        return (
            <PrimaryMenu appID={this.getAppID()} orientation={horizontal ? 'h' : 'v'} />
        );
    }

    buildContent() {
        this.profiler.log('Building the COntent COmp');
        return (
            <UC.Content tabbed={false} appID={this.getAppID()} />
        );
    }

    buildBottom() {
        const Footer = this.getFooter();
        return (
            <Footer appID={this.getAppID()} />
        );
    }

}
