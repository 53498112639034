import { Md5 } from 'md5-typescript';
import * as sanitizer from 'sanitize-html';
import './../styles/styles.css';
import { EM } from './event-mgmt.service';
import xmlFormat from 'xml-formatter';

declare var Razorpay: any;

export type ExternalUser = { mode: string, token?: any, profile?: any };
export const Images: any = { Icon: {} };
const mutationObservers: any = {};

export type UnoAppInfo = {
    id: string,
    title?: string,
    subtitle?: string,
    contact?: string,
    logo?: any,
}

export const Router = {
    ANY: '*',
    Common: {
        PARAM_DELIM: ':',
        PATH_DELIM: '/',
        PATH_HOME: 'home',
    },

    ParamType: {
        APPLICATION: 'aid',
        CATEGORY: 'cat',
        ENTITY_ID: 'eid',
        ACTION: 'act',
        FILTER: 'filter',
        MODE: 'mode',
        PERSON: 'pid',
        AUTH_TOKEN: 'token',
    },

    CatAction: {
        // on a category
        MAIN: 'main',
        LIST: 'list',
        SEARCH: 'search',
        CREATE: 'create',
        // on a specific entity_id
        EDIT: 'edit',
        VIEW: 'view',
        QUICK_VIEW: 'qview',
        DELETE: 'delete',
        SAVE: 'save',
    },
    AppAction: {// on an app
        HOME: 'home',
        SIGNUP: 'signup',
        SIGNIN: 'signin',
        PERMISSION: 'permission',
        SIGNIN_EXTERNAL: 'signin_external',
    },

    getRouteByParam: (paramType: any, paramVal?: any) => {
        let route: string =
            Router.Common.PATH_DELIM
            // + paramType
            // + Router.Common.PATH_DELIM
            + (paramVal ? paramVal : Router.getParamPlaceHolder(paramType));
        return route;
    },

    getPublicActions: () => { return [Router.AppAction.SIGNIN, Router.AppAction.SIGNUP] },

    getParamPlaceHolder: (paramType: any,) => {
        return Router.Common.PARAM_DELIM + paramType;
    },

    buildHomeRoute: (appID: any) => {
        return Router.buildRoute(appID, null, null, null,);
    },

    buildRoute: (appID?: any, categoryID?: any, action?: any, entityID?: any, params?: any,): string => {
        let route: string = '';
        if (appID !== null) {
            route += Router.getRouteByParam(Router.ParamType.APPLICATION, appID);
        }
        if (categoryID !== null) {
            route += Router.getRouteByParam(Router.ParamType.CATEGORY, categoryID);
        }
        if (action && action !== Router.Common.PATH_HOME) {
            route += Router.getRouteByParam(Router.ParamType.ACTION, action);
            switch (action) {
                case Router.CatAction.EDIT:
                case Router.CatAction.VIEW:
                    //case Router.CatAction.DELETE:
                    route += Router.getRouteByParam(Router.ParamType.ENTITY_ID, entityID);
                    break;
            }
        }
        return route;
    },

    buildEntityActionRoute: (entity: any, action: any): string => {
        if (entity) {
            return Router.buildRoute(entity.app_id, entity.category, action, entity._id);
        } else {
            return '';
        }
    },

    getViewRoute: (entity: any): string => {
        return Router.buildEntityActionRoute(entity, Router.CatAction.VIEW);
    },

    getEditRoute: (entity: any): string => {
        return Router.buildEntityActionRoute(entity, Router.CatAction.EDIT);
    },

    parseParams: (thePath: string) => {
        const params: any = {};
        if (!thePath) {
            return params;
        }

        Object.values(Router.ParamType).forEach(
            pType => {
                params[pType] = Router.parseParam(thePath, pType);
            }
        )

        if (!params[Router.ParamType.APPLICATION]) { // no app id.
            const pathname = thePath; //new URL(thePath).pathname;
            if (pathname) {
                const pathParts = pathname.split('/');
                pathParts.forEach((pp, ind) => {
                    switch (ind) {
                        case 1:
                            params[Router.ParamType.APPLICATION] = pp;
                            break;
                        case 2:
                            params[Router.ParamType.CATEGORY] = pp;
                            break;
                        case 3:
                            params[Router.ParamType.ACTION] = pp;
                            break;
                        case 4:
                            params[Router.ParamType.ENTITY_ID] = pp;
                            break;
                    }
                    // console.log(`${ind + 1} - ${pp}`);
                });
            }
        }

        return params;
    },

    parseParam: (url: string, paramName: any) => {
        // http://localhost:3000/aid/uno/cat/uno_theme_def
        // http://localhost:3000/eid/uno_theme_def_78451_81225_87257_62500/aid/uno/cat/uno_theme_def/act/edit
        // http://localhost:3000/uno/uno_theme_def
        // http://localhost:3000/uno/uno_theme_def/edit/uno_theme_def_78451_81225_87257_62500/

        let paramValue: any = undefined;
        const paramPath = '/' + paramName + '/';
        const paramPathIndex = url.indexOf(paramPath);
        if (paramPathIndex >= 0) {
            const paramValueStart = paramPathIndex + paramPath.length;
            let paramValueEnd = url.indexOf('/', paramValueStart);
            if (paramValueEnd < 0) {
                paramValueEnd = url.length;
            }
            paramValue = url.substring(paramValueStart, paramValueEnd);
        }
        return paramValue;
    },

    getGenericRoutes: (appID?: any) => {
        const routes: any = {};
        routes[Router.CatAction.MAIN] = Router.buildRoute(appID);
        routes[Router.CatAction.SEARCH] = Router.buildRoute(appID, undefined, Router.CatAction.SEARCH);
        routes[Router.CatAction.LIST] = Router.buildRoute(appID, undefined, Router.CatAction.LIST);
        routes[Router.CatAction.CREATE] = Router.buildRoute(appID, undefined, Router.CatAction.CREATE);
        routes[Router.CatAction.EDIT] = Router.buildRoute(appID, undefined, Router.CatAction.EDIT);
        routes[Router.CatAction.VIEW] = Router.buildRoute(appID, undefined, Router.CatAction.VIEW);
        // routes[Router.Action.QUICK_VIEW] = Router.buildRoute(appID, undefined, Router.Action.QUICK_VIEW);
        console.log('Generic Content Routes - ', routes);
        return routes;
    },

    getAbsRoute: (route: string) => {
        return `${Router.getOrigin()}${route}`;
    },

    getOrigin: () => {
        return Common.Window.location.origin;
    },

}

export class Profiler {
    private id: string | undefined;
    private origin = new Date();
    private since = new Date();
    disabled: boolean = (process.env.PROFILE_DISABLED === 'true');

    static init = (id?: string, enabled = true) => {
        const p = new Profiler();
        p.id = id;
        p.disabled = !enabled;
        return p;
    }

    reset = () => {
        this.since = new Date();
    }

    log = (msg: any, ...others: Array<any>) => {
        if (this.disabled) {
            return;
        }
        const now = new Date();
        console.log(now, 'DEBUG', this.getID(), `${this.msSince(now)} ms`, msg, ...others,);
    }

    error = (msg: any, err?: any, ...others: Array<any>) => {
        const now = new Date();
        console.error(now, 'ERROR', this.getID(), `${this.msSince(now)} ms`, msg, err, ...others,);
    }

    getID = () => {
        return this.id ? this.id : '';
    }

    getOriginTime = () => {
        return this.origin;
    }

    getSince = () => {
        return this.since;
    }

    msSince = (now: Date = new Date()) => {
        return (now.getTime() - this.since.getTime());
    }

}

export const Common = {
    Window: window,
    Profiler: Profiler,
    Router: Router,
    Styles: {
        getRules: (type?: number) => {
            const rules: Array<CSSRule> = [];
            const stylesheets = document.styleSheets;
            for (let ss of stylesheets) {
                for (let rule of ss.cssRules) {
                    if (type) {
                        if (rule.type === type) {
                            rules.push(rule);
                        }
                    } else {
                        rules.push(rule);
                    }
                }
            }
            return rules;
        },

        printCSSRules: () => {
            console.log('CSS Rules');
            const rules = Common.Styles.getRules();
            rules.forEach(r => {
                console.log(r.type, r.cssText);
            })
        },

        getClasses: () => {
            return Common.Styles.getRules(CSSRule.STYLE_RULE);
        },
    },

    ContentType: {
        JSON: 'application/json',
        FORM: 'application/x-www-form-urlencoded',
        MULTIPART: 'multipart/form-data',
        MULTIPART_BOUNDARY: 'multipart/form-data; boundary=----WebKitFormBoundary8WUT8fq7VOc0Q7GG',
    },

    Language: {
        Supported: {
            en: 'English',
            bn: 'Bengali - বাংলা',
            hi: 'Hindi - हिंदी',
            gu: 'Gujarati - ગુજરાતી',
            kn: 'Kannada - ಕನ್ನಡ',
            ml: 'Malyalam - മലയാളം',
            mr: 'Marathi - मराठी',
            ta: 'Tamil - தமிழ்',
            te: 'Telugu - తెలుగు',
            ur: 'Urdu - اردو',
            id: 'Indonesian',
        },

        getDefaultLanguage: (appID: string) => {
            let langID = window.localStorage.getItem(Common.Language.getStoreID(appID));
            const lang = navigator.language;
            if (langID === undefined && lang) {
                const locale = new Intl.Locale(lang);
                langID = locale.language;
            }
            return langID;
        },

        setDefaultLanguage: (appID: string, language?: string) => {
            const key = Common.Language.getStoreID(appID);
            if (language) {
                window.localStorage.setItem(key, language);
            } else {
                window.localStorage.removeItem(key);
            }

            EM.emit(Common.Event.APP_LANGUAGE_CHANGED, { appID: appID, language: language });
        },

        getStoreID: (appID: string) => {
            return `${appID}.language`;
        },
    },

    AppManager: {
        ID: 'uno_apps',
        PROP_APP_ID: 'app_info_id',
    },

    AppSettings: {

        setLink: (id: string, value: any) => {
            const document = Common.Window?.document;
            if (document && value) {
                let link: any = document.querySelector(`link[rel~='${id}']`);
                if (!link) {
                    link = document.createElement('link');
                    link.rel = id;
                    document.head.appendChild(link);
                }
                link.href = value;
            }
        },

        setMeta: (id: string, value: any) => {
            const document = Common.Window?.document;
            if (document && value) {
                let meta: any = document.querySelector(`meta[name~='${id}']`);
                if (!meta) {
                    meta = document.createElement('meta');
                    meta.name = id;
                    document.head.appendChild(meta);
                }
                meta.content = value;
            }
        },

        setIcon: (value?: string) => {
            Common.AppSettings.setLink('icon', value);
        },

        setThemeColor: (value?: string) => {
            Common.AppSettings.setMeta('theme-color', value);
        },

        setDescription: (value?: string) => {
            Common.AppSettings.setMeta('description', value);
        },

        setKeyword: (value?: string) => {
            Common.AppSettings.setMeta('keyword', value);
        },

        setManifest: (value?: any) => {
            value = Common.safeParse(value);
            let url = value;
            if (Common.checkType.Object(value)) {
                const manifest: any = Common.stringify(value);
                let content = encodeURIComponent(manifest);
                url = 'data:application/manifest+json,' + content;
            }

            Common.AppSettings.setLink('manifest', url);
        },


        setTitle: (value?: string) => {
            const document = Common.Window?.document;
            if (document && value) {
                document.title = value;
            }
        },

    },

    LS_SESSION: 'session',
    SESSION_VALIDITY_PERIOD: 60, // 60 seconds 
    SMALL_SCREEN: { WIDTH: 900, HEIGHT: 900 },

    isSmallScreen: () => {
        const screen = window?.screen;
        const small = (screen?.availWidth <= Common.SMALL_SCREEN.WIDTH && screen?.availHeight <= Common.SMALL_SCREEN.HEIGHT);
        return small;
    },
    Designer: {
        Mode: {
            DESIGN: 'design',
            PREVIEW: 'preview',
            LIVE: 'live',
        },

        PaletteGroup: {
            Frequent: { id: 'frequent', label: 'Frequent', maximized: true },
            Entity: { id: 'entity', label: 'Entity' },
            Editor: { id: 'editor', label: 'Editor' },
            Viewer: { id: 'viewer', label: 'Viewer' },
            Layout: { id: 'layout', label: 'Layout' },
            Integration: { id: 'integration', label: 'Integration' },
            Custom: { id: 'custom', label: 'Customized' },
            Other: { id: 'other', label: 'Others' },

        },
    },

    I18N: {
        Locales: [
            { id: 'hi', label: 'Hindi' },
            { id: 'hi_IN', label: 'Hindi - India' },
        ],
        getI18N: (attr: string) => {
            return `${attr}_i18n`;
        },
    },

    CategoryID: {
        AppConfig: 'uno_app_config',
        AppBackup: 'uno_app_backup',
        AppDef: 'uno_app_def',
        EmailConfig: 'uno_email_config',
        AppTemplate: 'uno_app_template',
        CategoryDef: 'uno_category_def',
        PropDef: 'uno_prop_def',
        FilterDef: 'uno_entity_filter',

        Schedule: 'uno_schedule',
        CheckDef: 'uno_check_def',
        ConditionDef: 'uno_condition_def',
        EventDef: 'uno_event_def',
        TriggerDef: 'uno_trigger_def',
        ActionDef: 'uno_action_def',
        JobRun: 'uno_job_run',
        FunctionDef: 'uno_function_def',

        ScreenDef: 'uno_screen_def',
        StyleDef: 'uno_style_def',
        MenuDef: 'uno_menu',
        MenuItemDef: 'uno_menu_item',

        Person: 'uno_person',
        Credential: 'uno_cred',
        UserGroup: 'uno_user_group',
        UserRole: 'uno_user_role',
        UserSession: 'uno_user_session',
        Resource: 'uno_resource',
        History: 'uno_history',
        UnoFile: 'uno_file',
        Payment: 'uno_payment',

        Plugin: 'uno_plugin',
        PluginStatus: 'uno_plugin_status',
        PluginConfig: 'uno_plugin_config',

        Workbook: 'uno_workbook',
        Worksheet: 'uno_worksheet',
        WSCell: 'uno_ws_cell',
        WSRow: 'uno_ws_row',
        WSColumn: 'uno_ws_column',
    },
    Schedule: {
        Frequncy: {
            ONCE: 'once',
            MINUTE: 'minute',
            HOURLY: 'hour',
            DAILY: 'day',
            WEEKLY: 'week',
            MONTHLY: 'month',
            YEARLY: 'year',
        },
    },
    Event: {
        SHOW_DIALOG: 'show_dialog',
        HIDE_DIALOG: 'hide_dialog',
        SHOW_MESSAGE: 'show_message',
        HIDE_MESSAGE: 'hide_message',
        APP_REGISTERED: 'app-registered',
        APP_UNREGISTERED: 'app-unregistered',

        CREATE_ENTITY_ACTION_RESOURCE: 'CREATE_ENTITY_ACTION_RESOURCE',
        FILTER_RESULT_EXPORT_REQUESTED: 'FILTER_RESULT_EXPORT_REQUESTED',

        CONFIGS_LOADED: 'CONFIGS_FROM_ENTITY_TYPE_DEFS_LOADED',
        APP_DEFS_LOADED: 'APP_CONFIGS_FROM_APP_DEFS_LOADED',
        THEME_DEFS_LOADED: 'THEME_DEFS_LOADED',
        APP_THEME_CHANGED: 'APP_THEME_CHANGED',
        APP_LAYOUT_CHANGED: 'APP_LAYOUT_CHANGED',
        APP_SWITCHED: 'APP_SWITCHED',
        SCREEN_COMPS_LOADED: 'SCREEN_COMPS_LOADED',
        SCREEN_COMPS_UNLOADED: 'SCREEN_COMPS_LOADED',

        ROUTE_CHANGED: 'ROUTE_CHANGED',
        ROUTE_CHANGE_REQUESTED: 'ROUTE_CHANGE_REQUESTED',
        ENTITY_PROP_CHANGED: 'ENTITY_PROP_CHANGED',
        SET_FOCUS: 'SET_FOCUS',


        SIGNED_UP: 'signed_up',
        SIGNED_IN: 'signed_in',
        SIGNED_OUT: 'signed_out',
        SIGN_OUT_REQUESTED: 'sign_out_requested',
        USER_SESSION_CREATED: 'user_session_created',
        USER_SESSION_CLEARED: 'user_session_cleared',

        CONTENT_SCROLLED: 'content_scrolled',
        PRINTABLE_CONTENT_RECEIVED: 'PRINTABLE_CONTENT_RECEIVED',
        PRINTABLE_CONTENT_REQUESTED: 'PRINTABLE_CONTENT_REQUESTED',

        APP_LANGUAGE_CHANGED: 'APP_LANGUAGE_CHANGED',

        Stage: {
            REQUESTED: 'requested',
            PROGRESSING: 'progressing',
            COMPLETED: 'completed',
            FAILED: 'failed',
        },
        getActionStage: (action: any, stage?: any) => {
            return (stage) ? `${action}-${stage}` : action;
        },
    },

    EMAIL_SETTINGS: {
        HOST: 'email_host',
        PORT: 'email_port',
        SECURE: 'email_secure',
        USER: 'email_user',
        PASSWORD: 'email_password',
    },
    Function: {
        prefix: '/Function(',
        suffix: ')/',
    },
    Datatype: {
        String: 'string',
        Function: 'function',
        Object: 'object',
        Number: 'number',
        Boolean: 'boolean',
    },
    ParamClosure: {
        prefix: `'`,
        suffix: `'`,
    },
    BlockClosure: {
        prefix: '{',
        suffix: '}',
    },

    checkType: {
        String: (data: any) => { return Common.isType(data, Common.Datatype.String) },
        Function: (data: any) => { return Common.isType(data, Common.Datatype.Function) },
        Object: (data: any) => { return Common.isType(data, Common.Datatype.Object) },
        Number: (data: any) => { return Common.isType(data, Common.Datatype.Number) },
        Boolean: (data: any) => { return Common.isType(data, Common.Datatype.Boolean) },
    },

    isEqualTo: (num: number, val: any) => {
        if (Common.checkType.Number(val) && val === num) {
            return true;
        } else if (Common.checkType.String(val)) {
            const numRE = new RegExp('[0-9]+');
            const matches = numRE.exec(val);
            // console.log('Column Width Value: ', width, matches);
            if (matches && matches.length > 0) {
                const parsedVal = Number.parseInt(matches[0]);
                return (parsedVal === num);
            }
        }
        return false;
    },

    isType: (data: any, type: string) => {
        return (data !== undefined && data !== null && typeof data === type);
    },

    isEqual: (first: any, second: any, log = false): boolean => {
        if (Common.checkType.Object(first) && Common.checkType.Object(second)) {
            // if both are BaseEntities
            if (first._id && first._id === second._id
                && first.category && first.category === second.category
                && first.app_id && first.app_id === second.app_id) {
                return true;
            }

            const keysFirst = Object.keys(first);
            const keysSecond = Object.keys(second);
            if (keysFirst.length !== keysSecond.length) {
                if (log) {
                    console.log(`Difference on key length: `, keysFirst, keysSecond, first, second);
                }
                return false;
            }

            const keys = [...keysFirst];
            keysSecond.forEach(k2 => {
                if (keys.indexOf(k2) < 0) {
                    keys.push(k2);
                }
            });

            for (let key of keys) {
                const firstVal = first[key];
                const secondVal = second[key];
                if (!Common.isEqual(firstVal, secondVal, log)) {
                    if (log) {
                        console.log(`Difference on key: `, key, firstVal, secondVal);
                    }
                    return false;
                }
            }
        } else {
            const res = (first === second);
            if (log) {
                console.log(`Same inputs: `, res, first, second);
            }
            return res;
        }
        // console.log(`Equal inputs: `, first, second);
        return true;
    },

    clearSpecial: (text: any, substitute: string = '_') => {
        return text.toString().replace(/[^\w\s]/gi, substitute);
    },

    sanitize: (val: string) => {
        // specify options.
        const options = {
            allowedTags: [
                'address', 'article', 'aside', 'footer', 'header', 'h1', 'h2', 'h3', 'h4',
                'h5', 'h6', 'hgroup', 'main', 'nav', 'section', 'blockquote', 'dd', 'div',
                'dl', 'dt', 'figcaption', 'figure', 'hr', 'li', 'main', 'ol', 'p', 'pre',
                'ul', 'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
                'em', 'i', 'kbd', 'mark', 'q', 'rb', 'rp', 'rt', 'rtc', 'ruby', 's', 'samp',
                'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr', 'caption',
                'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'img'
            ],
            disallowedTagsMode: 'discard',
            allowedAttributes: {
                a: ['href', 'name', 'target'],
                // We don't currently allow img itself by default, but this
                // would make sense if we did. You could add srcset here,
                // and if you do the URL is checked for safety
                img: ['src', 'style', 'class'],
                div: ['style', 'class'],
            },
            // Lots of these won't come up by default because we don't allow them
            selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
            // URL schemes we permit
            allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
            allowedSchemesByTag: {},
            allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
            allowProtocolRelative: true,
            enforceHtmlBoundary: false
        };
        // now sanitize and return
        return sanitizer(val, options);
    },

    addScript: (src: string, type?: string, isAsync: boolean = false, removeAndAdd: boolean = false,) => {
        let notExisting = true;
        const docEle = window.document;
        const scripts = docEle.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            const scEle = scripts.item(i);
            if (scEle && scEle.src === src) {
                if (removeAndAdd && scEle.parentNode) {
                    scEle.parentNode?.removeChild(scEle);
                } else {
                    notExisting = false;
                    break;
                }
            }
        }

        if (notExisting) {
            // console.log('Add a new Script: ', src, type, isAsync);
            const headEle = docEle?.head;
            if (docEle && headEle) {
                const scriptEle = docEle.createElement('script');
                scriptEle.setAttribute('src', src);
                if (type) {
                    scriptEle.setAttribute('type', type);
                }
                scriptEle.async = isAsync;
                headEle.appendChild(scriptEle);
            }
        }
    },

    stringify: (object: any, space = '\t') => {
        if (!object) {
            return undefined;
        }
        return JSON.stringify(
            object,
            function (key, value) {
                if (typeof value === Common.Datatype.Function) {
                    // console.log('Stringified : ', value);
                    return Common.Function.prefix + value.toString() + Common.Function.suffix;
                } else {
                    return value;
                }
            },
            space,
        );
    },

    safeParse: (json: any) => {
        try {
            return Common.parse(json);
        } catch (e) {
            return json;
        }
    },

    parse: (json: string | undefined) => {
        // console.log(`JSON to Parse: ${json}`,);
        try {
            if (json === undefined
                || Common.checkType.String(json) === false
                || (!json.trim().startsWith('[') && !json.trim().startsWith('{'))
            ) {
                throw Error(`Invalid input for parsing: ${json} `)
            }

            const obj = JSON.parse(json,
                function (key, value) {
                    return Common.parseFn(value);
                }
            );
            if (json?.length === 0) {
                console.log(`Parsing empty input, resulted into - `, obj);
            }

            return obj;
        } catch (e) {
            // console.log(`JSON Parsing Error: `, e);
            throw e;
        }
    },

    parseFn: (value: string) => {
        if (typeof value === Common.Datatype.String &&
            value.startsWith(Common.Function.prefix) &&
            value.endsWith(Common.Function.suffix)) {
            value = value.substring(Common.Function.prefix.length, (value.length - Common.Function.suffix.length));
            // console.log('Parsing Function: \n', key, ' :: \n', value);
            //value = babel.transform(value)
            return eval(value);
            // return (0, eval)(Common.ParamClosure.prefix + value + Common.ParamClosure.suffix);
        } else {
            return value;
        }
    },

    copy: (source: any, shallow: boolean = false) => {
        if (shallow) {
            return Object.assign({}, source);
        } else {
            return Common.safeParse(Common.stringify(source));
        }
    },

    getAllFuncs: (inputObj: any) => {
        var functions: any = {};
        var obj = inputObj;
        do {
            const names = Object.getOwnPropertyNames(obj);
            names.forEach((name: any) => {
                const val = inputObj[name];
                if (!functions[name] && typeof val === Common.Datatype.Function) {
                    functions[name] = val;
                }
            })
        } while (obj === Object.getPrototypeOf(obj));

        return functions;
    },

    instanceOf: (object: any, type: any) => {
        let result = true;
        if (typeof type === Common.Datatype.Object
            && typeof object === Common.Datatype.Object) {
            Object.keys(type).forEach(
                (member: any) => {
                    if (!(member in object)) {
                        result = false;
                    }
                }
            )
        } else if (typeof object === typeof type) {
            result = true;
        } else {
            result = false;
        }
        return result;
    },

    shuffle: (array: []) => {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    },

    getUniqueKey: (categoryID?: string) => {
        let prefix = 'field';
        if (categoryID) {
            prefix = categoryID;
        }
        return prefix + Math.round(Math.random() * 9999999);
    },

    calculateDistance: (lat1: number, lon1: number, lat2: number, lon2: number) => {
        var R = 6378137; // meters
        var dLat = Common.toRad((lat2 - lat1));
        var dLon = Common.toRad((lon2 - lon1));
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Common.toRad(lat1)) * Math.cos(Common.toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    },

    toRad: (num: number) => {
        return num * Math.PI / 180;
    },

    Math: {
        standardDeviation: (values: Array<number>) => {
            var avg = Common.Math.average(values);

            var squareDiffs = values.map(value => {
                var diff = value - avg;
                var sqrDiff = diff * diff;
                return sqrDiff;
            });

            var avgSquareDiff = Common.Math.average(squareDiffs);

            var stdDev = Math.sqrt(avgSquareDiff);
            // console.log(`Avg: ${avg}, Avg Sq Diff: ${avgSquareDiff}, SD: ${stdDev}`);
            return stdDev;
        },

        average: (data: Array<number>) => {
            if (data.length > 0) {
                const avg = Common.Math.sum(data) / data.length;
                return avg;
            } else {
                return 0;
            }
        },

        sum: (data: Array<number>) => {
            var sum = data.reduce((sum, value) => {
                return sum + value;
            }, 0);
            return sum;
        }
    },

    Formatter: {
        Currency: {
            format: (value: number, locale?: string, currency?: string) => {
                const numFormat = new Intl.NumberFormat(locale || 'en-IN', { style: 'currency', currency: currency || 'INR' });
                return numFormat.format(value);
            },
        },
        XML: {
            format: (text: string, options: {}) => {
                return xmlFormat(text, options);
            }
        },
    },

    pause: async (time: number) => {
        return new Promise((resolve, reject) => {
            // console.log(`Pause for ${time} seconds`)
            const interval = setInterval(() => {
                clearInterval(interval);
                resolve(time);
            }, 1000 * time);
        }).then(t => {
            // console.log(`Pause for ${t} seconds is now over`);
            return;
        }).catch(e => {
            // console.log(`Problem in pausing for ${time} seconds`);
            return;
        });
    },

    showMessage: (message: any, clearAll: boolean = false, timeOut?: number, consoleLog: boolean = false) => {
        if (consoleLog) {
            console.log(message);
        }
        EM.emit(Common.Event.SHOW_MESSAGE, { message: message, timeOut: timeOut, clear: clearAll });
    },

    hideMessage: (clbk: Function = () => { }) => {
        EM.emit(Common.Event.HIDE_MESSAGE, clbk);
    },

    showError: (message: any, clearAll: boolean = false, timeOut?: number, consoleLog: boolean = false) => {
        if (consoleLog) {
            console.log(message);
        }
        EM.emit(Common.Event.SHOW_MESSAGE,
            {
                message: `<b style='color: red;'>${message}</b>`,
                timeOut: timeOut,
                clear: clearAll,
            }
        );
    },

    hideError: (clbk: Function = () => { }) => {
        EM.emit(Common.Event.HIDE_MESSAGE, clbk);
    },

    routeTo: (route?: string) => {
        const location = Common.Window?.location;
        const path = location?.pathname;
        const href = location?.href;
        const baseURL = href.substring(0, href.indexOf(path));

        if (route) {
            if (route.startsWith(baseURL)) {
                route = route.substring(baseURL.length);
            } else if (location && (route.startsWith('http') || route.startsWith('mailto'))) {
                location.assign(route);
                return;
            }
        } else {
            route = path;
        }

        EM.emit(Common.Event.ROUTE_CHANGE_REQUESTED, route);
    },

    reload: () => {
        window.location.reload();
    },

    showDialog: (inputs: any) => {
        EM.emit(Common.Event.SHOW_DIALOG, inputs);

        if (inputs.timeout) {
            setTimeout(
                () => {
                    Common.hideDialog(inputs.clbk, inputs.id);
                },
                1000 * inputs.timeout
            );
        }
    },

    hideDialog: (clbk: Function = () => { }, id?: string) => {
        EM.emit(Common.Event.HIDE_DIALOG, { clbk: clbk, id: id, });
    },

    // UI functions
    registerEventHandlers: (theComp: any) => {
        let _event_handlers: Array<string> = [];
        const handlers: Array<any> = theComp?.props?.handlers;
        if (handlers?.length > 0) {
            // console.log('Event Handlers: ', eventHandlers);
            _event_handlers = handlers.map(eh => {
                const handler = EM.register(eh.event, async (data: any) => {
                    const eh_fn = eh.fn;
                    if (eh_fn) {
                        await eh_fn({ theComp: theComp, data: data });
                    }
                });
                // console.log('Registered Component Event Handler: ', eh.event, handler, theComp?.state?._uuid_, { ...theComp?.state });
                return handler;
            });
        }
        return _event_handlers;
    },

    unregisterEventHandlers: (handles: Array<string>, theComp?: any) => {
        handles?.forEach(h => {
            // console.log('Unregister Component Event Handler: ', h, theComp?.state?._uuid_, { ...theComp?.state });
            EM.unregister(h);
        })
    },

    notifyEvent: async (theComp: any, event: string, data: any = {}) => {
        const props = theComp?.props;
        const eventFn = props ? props[event] : undefined;
        if (eventFn) {
            // console.log('Trigger Event: ', event, data);
            await eventFn({ theComp: theComp, ...data, event: event });
        }
    },

    startObservation: (
        eleID: string,
        callback = (mutationList: any, observer: any) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    console.log('A child node has been added or removed.', mutation);
                } else if (mutation.type === 'attributes') {
                    console.log(`The ${mutation.attributeName} attribute was modified.`, mutation);
                }
            }
        }
    ) => {
        // Select the node that will be observed for mutations
        const targetNode: any = document.getElementById(eleID);
        console.log('Trying to observe element: ', eleID, targetNode);

        if (!targetNode || targetNode.nodeType !== 1) {
            return undefined;
        }

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        const observerID = Common.getUniqueKey('MutationObservers_');
        mutationObservers[observerID] = observer;
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
        console.log('Started Observing HTML element: ', eleID, targetNode);
        return observerID;
    },

    stopObservation: (observerID: string) => {
        const observer = mutationObservers[observerID];
        // stop observing
        if (observer?.disconnect) {
            observer.disconnect();
        }
    },

    encrypt: (val: string) => {
        return Md5.init(val);
    },

    toBase64: (buffer: any) => {
        var binary = '';
        if (Common.checkType.String(buffer)) {
            binary = buffer;
        } else {
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
        }
        return Common.Window.btoa(binary);
    },

    Payments: {
        Event: {
            FIND_PAYMENT_GATEWAYS: 'find_payment_gateways'
        },
        PayStatus: {
            CREATED: 'created',
            COMPLETED: 'completed',
        },

        getGatewayIcon: (id: string) => {
            return Images.Icon[id];
        },

        Gateways: {
            Razorpay: {
                id: 'Razorpay',
                label: 'Razorpay',
                /*
                createOrder: async (inputs: any, cred = { id: undefined, secret: undefined }) => {
                    return await RemoteService.getData(await RemoteService.post('payment/order',
                        { inputs: inputs, gateway: { id: Common.Payments.Gateways.Razorpay.id, cred: cred } }
                    ));
                },
                initPayment: async (payOptions: any) => {
                    const rzp = new Razorpay(payOptions);
                    rzp.open();
                },
                paymentOptions: {
                    key: '',
                    amount: '',
                    currency: 'INR',
                    name: '',
                    prefill: {
                        name: '',
                        email: '',
                        contact: '',
                    },
                    description: 'TCP Payment',
                    order_id: '',
                    handler: (res: any) => {
                        // console.log(res);
                        // alert('Payment status: ' + Common.stringify(res));
                    },
                },
                */
            }
        }
    },

}
