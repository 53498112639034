import React, { useState } from "react";
import { FacebookProvider, LoginButton, useLogin } from "react-facebook";
import { EntityConstants } from "../../../@uno/api";
import { ExternalUser } from "../../../@uno/api/common.service";
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from "../../../@uno/core";
import icon_sign from './fb-signin-button.png';

function LoginButtonExample() {
    function handleSuccess(response: any) {
        console.log(response.status);
    }

    function handleError(error: any) {
        console.log(error);
    }

    return (
        <LoginButton
            scope="email"
            onError={handleError}
            onSuccess={handleSuccess}
        >
            Login via Facebook
        </LoginButton>
    );
}

function UnoFacebookLogin(props: { onSuccess: (data: ExternalUser) => void, graphData: any }) {
    const [profile, setProfile] = useState();

    const { login, status, isLoading, error } = useLogin();

    const handleLogin = async () => {
        try {
            const response = await login({
                scope: 'email',
            });

            console.log(response.status);
        } catch (error: any) {
            console.log(error.message);
        }
    }
    const theProfile: any = profile;

    return (
        <div>
            {theProfile ? (
                <div>
                    <div id="profile-div">
                        <div id="profile-div">
                            <p>
                                <strong>First Name: </strong> {theProfile.givenName}
                            </p>
                            <p>
                                <strong>Last Name: </strong> {theProfile.surname}
                            </p>
                            <p>
                                <strong>Email: </strong> {theProfile.userPrincipalName}
                            </p>
                            <p>
                                <strong>Id: </strong> {theProfile.id}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <img
                    title='Sign In with Facebook'
                    src={icon_sign}
                    className=' signin signin-external '
                    onClick={handleLogin}
                />
            )}
        </div>
    );
}

@UnoComponent({
    id: 'UnoFacebookLogin',
    label: 'Facebook Login',
    props: [
        { id: 'onSuccess', label: 'On Login Success', dataType: EntityConstants.PropType.FUNCTION, },
    ],
    getDesign: () => { return (<div><button>Sign in with Facebook 🚀 </button></div>); },
    getPreview: () => { return (<div><button>Sign in with Facebook 🚀 </button></div>); },
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class UnoFacebookLoginComp extends UnoCoreBaseComp {

    buildComp(): JSX.Element | null | undefined {
        const theProps: any = this.props;
        return (
            <>
                <UnoFacebookLogin {...theProps} />
            </>
        );
    }
}